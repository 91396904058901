<template>
   <section>
      <v-container fluid>


         <v-row v-if="!pageData.isLoading">
            <v-col cols="12" class="" v-if="temp != 'temp_3'">
               <v-btn color="secondary" x-small class="d-block mt-3 mi-start-auto" 
                  @click="exportPDF" outlined icon fab>
                  <v-icon>mdi-printer-outline</v-icon>
               </v-btn>
            </v-col>
            <v-col cols="12" class="">
               <ShiftPrint1 v-if="temp == 'temp_1'" :report="report" :printStaticData="printStaticData" />
               <ShiftSummery3 :report_data="report_data" :id="+$route.params.id" :auto_print="false"
                  v-else-if="temp == 'temp_3'" :report="report" :shift="report" />
               <ShiftPrint2 :expenses="expenses" :is_view="true" v-else :report="report"
                  :printStaticData="printStaticData" />
            </v-col>
         </v-row>
         <v-col cols="auto" class="my-16" v-if="pageData.isLoading">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
               indeterminate>
            </v-progress-circular>
            <p class="text-center">{{ $t("please wait") }}</p>
         </v-col>
         <div>
            <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
               :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="pageData.entityName"
               :pdf-quality="3" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
               :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
               <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">

                  <PrintPDF :header="[]" :maxPDFRows="5" :data="expenses" :header_slot="false" :content_slot="true"
                     :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
                     :customize_header_footer="true" :printStaticData="printStaticData">
                     <template v-slot:pdf_content="{ page, table_data, isLastPage }">

                        <v-col id="print_title" v-if="page == 1">
                           {{ `${$t('workday report')}` }}
                        </v-col>

                        <ShiftPrint1 v-if="temp == 'temp_1'" :report="report" :printStaticData="printStaticData" />
                        <ShiftPrint2 :isLastPage="isLastPage" :page="page" :expenses="table_data" :is_view="false"
                           v-if="temp == 'temp_2'" :report="report" :printStaticData="printStaticData" />


                     </template>
                  </PrintPDF>

               </section>
            </vue-html2pdf>
         </div>

      </v-container>
   </section>
</template>



<script>
import PrintPDF from "@/components/PrintPDF.vue";
import ShiftPrint1 from './ShiftPrint1.vue';
import ShiftPrint2 from './ShiftPrint2.vue';
import VueHtml2pdf from 'vue-html2pdf'
import ShiftSummery3 from "@/views/Auth/ShiftSummery3.vue";
export default {
   name: "ShiftReportTemplate1",

   data: () => ({
      pageData: {
         screen_code: "06-014",
         url: null,
         controlRoute: "/sales/list_shifts-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: false,
         isEdit: false,
         editIsLoading: false,
      },
      report: Object,
      printStaticData: Object,
      report_data: Object,
      expenses: [],
      temp: 'temp_1'
   }),
   watch: {
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
      ShiftPrint1,
      ShiftPrint2,
      ShiftSummery3,
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.viewForm = true;
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`shift/${this.$route.params.id}`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.report = response.data.data;
                  this.printStaticData = response.data.print;
                  this.expenses = response.data.data.ec_list.length ? response.data.data.ec_list : [{}];
                  this.temp = response.data.print.temp;
                  if (this.temp === 'temp_3') {
                     this.pageData.isLoading = true;
                     this.$api.POST_METHOD('daily_transactions', { shift_id: +this.$route.params.id }, false)
                        .then(({ check, data }) => {
                           this.pageData.isLoading = false;
                           if (check) {
                              this.report_data = data;
                           }
                        });
                  }
               }
            })
         } else {
            this.$router.push('/')
         }
      },
      exportPDF() {
         this.$refs.html2Pdf.generatePdf();
      },

   },

};
</script>
<style lang="scss" scoped>
#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 5px;
   font-weight: bold;
   margin: 20px auto;
}
</style>