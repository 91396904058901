<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="680">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded-md class="bg-white mt-3" v-bind="attrs" v-on="on" depressed>
          <img class="mx-1" src="@/assets/img/svg/pay1.svg" alt="Qarat-POS" />
          <span class="d-md-block d-none">{{ type ? $t(type) : $t("choose payment method") }}</span>
        </v-btn>
      </template>
      <v-card relative>
        <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="dialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-center">
          {{ type ? $t(type) : $t("choose payment method") }} 
          <v-btn color="error" x-small v-if="type" @click="() => type = null" class="shadow mx-2">
            {{ $t("Edit")}}</v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-col cols="12">
          <v-row  class="pa-4">
            <v-col cols="3" v-for="(type, index) in paymentsTypes" :key="index">
              <v-card :disabled="type.disabled" outlined height="120"
                class="ma-auto overflow-hidden rounded-xl d-flex justify-center align-center mt-2"
                @click="changeType(type.id)" width="125" :title="$t(type.id)">
                <div>
                  <img :src="type.image" height="100" width="100" :alt="$t(type.name)" class="d-block ma-auto">
                  <div class="subtitle-2 mt-n1 text-center">
                    {{ type.disabled ? $t('(please choose machine)') : $t(type.name) }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>


      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss"></style>



<script>

export default {
  name: "PaymentMethodNotice",
  props: {
    backValueMethod: { type: Function },
    type: { default: "" },
    machineID: { default: "" }
  },
  watch: {
    type() {
      this.dialog = false;
      switch (this.type) {
        case 'payCash':
          this.backValueMethod(1)
          break;
        case 'payBank':
          this.backValueMethod(2)
          break;
        case 'transferPayment':
          this.backValueMethod(3)
          break;
        case 'payTamara':
          this.backValueMethod(7)
          break;
        case 'payTabby':
          this.backValueMethod(8)
          break;
        case 'paySTC':
          this.backValueMethod(10)
          break;
        case 'paySalla':
          this.backValueMethod(11)
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyP") {
        this.dialog = true;
      }
      if (e.code == 'Escape') {
        this.dialog = false;
      }
    });
  },
  computed: {
    paymentsTypes() {
      return [
        { id: 'payCash', name: this.$t('payCash'), image: require('@/assets/img/payment/cash.svg') },
        { id: 'payBank', name: this.$t('payBank'), image: require('@/assets/img/payment/bank.svg'), disabled: !this.machineID },
        // { id: 'payCashBank', name: this.$t('payCashBank'), image: require('@/assets/img/payment/cash_bank.svg'), disabled: !this.machineID },
        { id: 'transferPayment', name: this.$t('transferPayment'), image: require('@/assets/img/payment/transfer.svg') },
        // { id: 'transferAndCashPayment', name: this.$t('transferAndCashPayment'), image: require('@/assets/img/payment/cash_transfer.svg') },
        // { id: 'paymentTransferAndBank', name: this.$t('paymentTransferAndBank'), image: require('@/assets/img/payment/bank_transfer.svg'), disabled: !this.machineID },
        { id: 'payTamara', name: this.$t('payTamara'), image: require('@/assets/img/payment/tamara.svg') },
        { id: 'payTabby', name: this.$t('payTabby'), image: require('@/assets/img/payment/tabby.svg') },
        { id: 'paySTC', name: this.$t('paySTC'), image: require('@/assets/img/payment/stc.svg') },
        { id: 'paySalla', name: this.$t('paySalla'), image: require('@/assets/img/payment/salla.png') },
      ]
    }

  },
  components: {
  },
  methods: {
    changeType(id) {
      this.type = id;
      this.checkPaymentType()
    },
    checkPaymentType() { },

  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
