<template>
  <section id="DailyTransactionsPrint">
    <div style="display : flex; justify-content : space-between">
      <h5 style="margin : 0 3px"><b>{{ selectedShift.start_date }} - {{ selectedShift.end_date }}</b></h5>
      <h5 style="margin : 0 3px" v-if="selectedShift.user_name">{{ $t('employee') }} : <b>{{ selectedShift.user_name || '-' }}</b></h5>
    </div>
    <div class="sectionCard wrap center" style="margin : 10px 0 !important;">
      <table v-for="(table, tableIndex) in ['moneyTransactions', 'usedTransactions']" :key="tableIndex">
        <thead>
          <tr>
            <th v-for="(header, headerIndex) in tableHeader" :key="headerIndex"><b>{{ $t(header.text) }}</b></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in $props[table]" :key="index">
            <td v-for="(header, headerIndex) in tableHeader" :key="headerIndex" dir="ltr">
              <template v-if="header.type === 'non_zero_float'">
                {{ row[header.key] | nonZero }}
              </template>
              <template v-else>
                {{ $t(row[header.key]) }}
              </template>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-for="(row, index) in [calculatedTotals(tableHeader, $props[table])]" :key="index">
            <th v-for="(header, headerIndex) in tableHeader" :key="headerIndex" dir="ltr">
              <template v-if="header.type === 'non_zero_float'">
                {{ row[header.key] | nonZero }}
              </template>
              <template v-else>
                {{ $t(row[header.key]) }}
              </template>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div style="border: 1px solid #999;border-radius: 8px; padding: 3px;width : 95vw !important; margin : auto">
      <div class="sectionCard wrap" v-for="(section, sectionIndex) in computedSections" :key="sectionIndex">
        <h5>{{ $t(section.label) }} : </h5>
        <div class="card block" v-for="( item, index) in section.data" :key="index">
          <div class="me-2 font-weight-bold">{{ $t(item.title) }}</div>
          <div class="me-2 bold">{{ item.value | nonZero }}
            <template v-if="section.unit">
              {{ $t(item.unit) }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <h5 style="margin : 10px 0 !important" v-if="shiftTransactions?.length && printWithTransactions">
      {{ $t('shift transactions') }}</h5>
    <div style="width : 100vw !important" class="sectionCard wrap center"
      v-if="shiftTransactions?.length && printWithTransactions">
      <table style="width : 95vw !important" fixed-header>
        <thead class="light">
          <tr class="light">
            <th class="light" v-for="(header, index) in detailsTableHeader" :key="index">
              {{ $t(header.text) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(detail, index) in shiftTransactions" :key="index">
            <td v-for="(header, headerIndex) in detailsTableHeader" :key="headerIndex"
              :class="{ 'red12': detail.sign == '-' }" dir="ltr">
              <template v-if="header.type == 'float' || header.type == 'weight'">
                {{ detail.sign == '-' && detail[header.key] ? '( ' : '' }}
                {{ detail[header.key] || 0 | nonZero }}
                {{ detail.sign == '-' && detail[header.key] ? ' )' : '' }}
              </template>
              <template v-else>{{ $t(detail[header.key]) }}</template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>

export default {
  name: "DailyTransactionsPrint",
  props: {
    tableHeader: { default: [] },
    moneyTransactions: { default: [] },
    usedTransactions: { default: [] },
    drawer: { default: [] },
    totalSales: { default: [] },
    totalPurchases: { default: [] },
    supplierPayments: { default: [] },
    totalDeposits: { default: [] },
    totalExpenses: { default: [] },
    printWithTransactions: { default: 1 },
    shiftTransactions: { default: [] },
    selectedShift: { default: {} },
    from_date: { default: null },
    to_date: { default: null },
    user_name: { default: null },
  },
  mixins: [],
  data: () => ({}),
  computed: {
    detailsTableHeader() {
      let header = [
        { text: "#", key: "m_id", type: "text" },
        { text: "action", key: "type_title", type: "text" },
        { text: "cash", key: "paid_cash", type: "float" },
        { text: "bank", key: "paid_bank", type: "float" },
        { text: "trans", key: "paid_trans", type: "float" },
        { text: "tabby", key: "paid_tabby", type: "float" },
        { text: "tamara", key: "paid_tamara", type: "float" },
        { text: "stc", key: "paid_stc", type: "float" },
        { text: "nearpay", key: "paid_nearpay", type: "float" },
        { text: "salla", key: "paid_salla", type: "float" },
        { text: "total amount", key: "final_total", type: "float" },
        { text: "caliber 18", key: "weight_18", type: "weight" },
        { text: "caliber 21", key: "weight_21", type: "weight" },
        { text: "caliber 22", key: "weight_22", type: "weight" },
        { text: "caliber 24", key: "weight_24", type: "weight" },
        { text: "total weight", key: "total_weight", type: "float" },
      ]
      return header
    },
    computedSections() {
      return [
        { label: 'actual in the drawer', data: this.drawer },
        { label: 'total sales', data: this.totalSales, unit: true },
        { label: 'total purchases', data: this.totalPurchases, unit: true },
        { label: 'total deposits', data: this.totalDeposits, unit: true },
        { label: 'total expenses', data: this.totalExpenses, unit: true },
        { label: 'supplier payments', data: this.supplierPayments, unit: true },
      ]
    },
    calculatedTotals() {
      return (header = [], data = []) => {
        const HEADER = header?.length ? header : this.tableHeader;
        const DATA = data?.length ? data : this.pageData.rows;
        const CALCULATE_TOTAL = (property) => DATA.reduce((prevValue, row) => {
          return prevValue + row[property];
        }, 0);
        let totalObj = {};
        HEADER.forEach((header, index) => {
          switch (header.type) {
            case 'float':
            case 'non_zero_float':
            case 'weight':
            case 'number':
            case 'price':
              totalObj[header.key] = CALCULATE_TOTAL(header.key);
              break;
            default:
              totalObj[header.key] = !index ? this.$t('total') : '';
              break;
          }
        });
        totalObj.sub_category_full = DATA.length + ' ' + this.$t('item');
        return totalObj;
      }
    },
  },
  watch: {},
  mounted() { },
  methods: {},
};
</script>

<style lang="scss">
#DailyTransactionsPrint {
  padding: 3px;

  .sectionCard {
    display: flex;

    &.wrap {
      flex-wrap: wrap;

      &.center {
        justify-content: center;
      }
    }

    margin-top: 4px;

    table {
      margin: 4px;
      width: 510px;

      th {
        background: #000;
        color: #fff;
        border: 1px solid transparent !important;
        font-size: 11px !important;
        text-align: center
      }

      td {
        font-size: 11px !important;
        text-align: center
      }

      tr:nth-of-type(odd) {
        background: #fff;
        border: 1px solid transparent !important;

        td {
          background: #fff;
          border: 1px solid transparent !important;
        }
      }

      tr:nth-of-type(even) {
        background: #fff;
        border: 1px solid transparent !important;

        td {
          background: #f8fafc;
        }
      }

      tfoot td {
        font-weight: 600;
      }
    }

    .me-2 {
      margin-inline-end: 5px;
    }

    h5 {
      padding: 5px 0;
      margin: 0 5px;
      width: 100px;
      font-size: 11px !important;
    }

    .card {
      border: 1px solid #000;
      padding: 3px 6px;
      display: flex;
      margin: 2px;
      border-radius: 5px;
      min-width: 80px;
      font-size: 10px !important;

      .bold {
        font-weight: 700;
      }

      &.block {
        display: block;
      }
    }
  }
}
</style>