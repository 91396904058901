import Home from "../views/Home.vue";
// Purchases
import PurchasesTableVue from "../views/Used/PurchasesTable.vue";
import PurchasesVue from "../views/Used/Purchases.vue";
import PurchasesInvoices from "../views/Used/PurchasesInvoices.vue";
// New Purchases
import UsedPurchasesTable from "../views/UsedPurchases/UsedPurchasesTable.vue";
import UsedPurchases from "../views/UsedPurchases/UsedPurchases.vue";
import UsedPurchasesInvoices from "../views/UsedPurchases/UsedPurchasesInvoices.vue";
import UsedPurchasesInvoicesControl from "../views/UsedPurchases/UsedPurchasesInvoicesControl.vue";
// Sales
import SalesVue from "../views/Sales/Sales.vue";
import SalesTableVue from "../views/Sales/SalesTable.vue";
import SalesInvoicesQTYVue from "../views/Sales/SalesInvoicesQTY.vue";
import SalesInvoicesPieceVue from "../views/Sales/SalesInvoicesPiece.vue";
// Return Sales
import ReturnSalesVue from "../views/ReturnSales/ReturnSales.vue";
import ReturnSalesTableVue from "../views/ReturnSales/ReturnSalesTable.vue";
import ReturnSalesCreateVue from "../views/ReturnSales/ReturnSalesCreate.vue";
import ReturnSalesPieceVue from "../views/ReturnSales/ReturnSalesPiece.vue";
// Notice Creditor && Debtor
import Notice from "../views/Notice/Notice.vue";
import CreditorTable from "../views/Notice/Creditor/CreditorTable.vue";
import CreditorCreate from "../views/Notice/Creditor/CreditorCreate.vue";
import DebtorTable from "../views/Notice/Debtor/DebtorTable.vue";
import DebtorCreate from "../views/Notice/Debtor/DebtorCreate.vue";
// Expenses
import Expenses from "../views/Expenses/Expenses.vue";
import ExpensesTable from "../views/Expenses/ExpensesTable.vue";
import ExpensesCreate from "../views/Expenses/ExpensesCreate.vue";
import ExpensesView from "../views/Expenses/ExpensesView.vue";
// Deposits
import Deposits from "../views/Deposits/Deposits.vue";
import DepositsTable from "../views/Deposits/DepositsTable.vue";
import DepositsCreate from "../views/Deposits/DepositsCreate.vue";
import DepositsView from "../views/Deposits/DepositsView.vue";

// Transfer
import Transfers from "../views/Transfers/Transfers.vue";
import TransfersTable from "../views/Transfers/TransfersTable.vue";
import CashTransfer from "../views/Transfers/CashTransfer.vue";
import MetalTransfer from "../views/Transfers/MetalTransfer.vue";
import TransfersView from "../views/Transfers/TransfersView.vue";
// Gold Permission
import GoldPermission from "../views/GoldPermission/GoldPermission";
import GoldPaymentPermissionTable from "../views/GoldPermission/GoldPaymentPermission/GoldPaymentPermissionTable.vue";
import GoldPaymentPermissionCreate from "../views/GoldPermission/GoldPaymentPermission/GoldPaymentPermissionCreate.vue";
import GoldReceiptPermissionTable from "../views/GoldPermission/GoldReceiptPermission/GoldReceiptPermissionTable.vue";
import GoldReceiptPermissionCreate from "../views/GoldPermission/GoldReceiptPermission/GoldReceiptPermissionCreate.vue";
// Reports
import Reports from "../views/Reports/Reports";
import ShiftReports from "../views/Reports/ShiftReports/ShiftReports.vue";
import ShiftReportsTable from "../views/Reports/ShiftReports/ShiftReportsTable.vue";
import ShiftReportControl from "../views/Reports/ShiftReports/ShiftReportControl.vue";
import CustomerSupplierStatementTable from "../views/Reports/CustomerSupplierStatement/CustomerSupplierStatementTable.vue";
import SupplierStatementTable from "../views/Reports/SupplierStatement/SupplierStatementTable.vue";
import FinalReportEntity from "@/views/Reports/FinalReportEntity/FinalReportEntity.vue";
import FullReportEntity from "@/views/Reports/FullReportEntity/FullReportEntity.vue";
import BarcodeReportEntity from "@/views/Reports/BarcodeReportEntity/BarcodeReportEntity.vue";

// POS Routes:-
export const POSRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // Purchases
  {
    path: "/purchases",
    name: "Purchases",
    component: PurchasesVue,
    children: [
      {
        path: "/purchases",
        name: "/Purchases",
        component: PurchasesTableVue,
      },
      {
        path: "/purchases/create-purchases-invoice",
        name: "/PurchasesInvoice",
        component: PurchasesInvoices,
      },
    ],
  },
  // Used Purchases
  {
    path: "/used-purchases",
    name: "UsedPurchases",
    component: UsedPurchases,
    children: [
      {
        path: "/used-purchases",
        name: "UsedPurchases",
        component: UsedPurchasesTable,
      },
      {
        path: "/used-purchases/create-purchases-invoice",
        name: "UsedPurchasesInvoice",
        component: UsedPurchasesInvoices,
      },
      {
        path: "/used-purchases/edit-purchases-invoice/:id",
        name: "UsedPurchasesInvoiceEdit",
        component: UsedPurchasesInvoices,
      },
      {
        path: "/used-purchases/purchases-invoice/:id",
        name: "UsedPurchasesInvoicesControl",
        component: UsedPurchasesInvoicesControl,
      },
    ],
  },
  // Sales
  {
    path: "/sales",
    name: "Sales",
    component: SalesVue,
    children: [
      {
        path: "/sales",
        name: "SalesTableVue",
        component: SalesTableVue,
      },
      {
        path: "/sales/create-sales-invoice",
        name: "SalesInvoiceQTY",
        component: SalesInvoicesQTYVue,
      },
      {
        path: "/sales/sales-invoice/:id",
        name: "SalesInvoiceQTYShow",
        component: SalesInvoicesQTYVue,
      },
      {
        path: "/sales/sales-invoice-Piece",
        name: "SalesInvoicePiece",
        component: SalesInvoicesPieceVue,
      },
    ],
  },
  // Return Sales
  {
    path: "/return-sales",
    name: "ReturnSalesVue",
    component: ReturnSalesVue,
    children: [
      {
        path: "/return-sales",
        name: "ReturnSalesTableVue",
        component: ReturnSalesTableVue,
      },
      {
        path: "/return-sales/create-return-sales",
        name: "ReturnSalesCreate",
        component: ReturnSalesCreateVue,
      },
      {
        path: "/return-sales/create-return-sales-piece",
        name: "ReturnSalesPiece",
        component: ReturnSalesPieceVue,
      },
    ],
  },
  // Notice
  {
    path: "/notice",
    name: "Notice",
    component: Notice,
    redirect: "/notice/creditor",
    children: [
      // Notice Debtor
      {
        path: "/notice/debtor",
        name: "DebtorTable",
        component: DebtorTable,
      },
      {
        path: "/notice/create-notice-debtor",
        name: "DebtorCreate",
        component: DebtorCreate,
      },
      // Notice Creditor
      {
        path: "/notice/creditor",
        name: "CreditorTable",
        component: CreditorTable,
      },
      {
        path: "/notice/create-notice-creditor",
        name: "CreditorCreate",
        component: CreditorCreate,
      },
      {
        path: "/notice/create-notice/:id",
        name: "CreditorEdit",
        component: CreditorCreate,
      },
    ],
  },
  // Expenses
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
    children: [
      {
        path: "/expenses",
        name: "ExpensesTable",
        component: ExpensesTable,
      },
      {
        path: "/expenses/create-expenses",
        name: "ExpensesCreate",
        component: ExpensesCreate,
      },
      {
        path: "/expenses/:id",
        name: "ExpensesView",
        component: ExpensesView,
      },
    ],
  },
  // Deposits
  {
    path: "/deposits",
    name: "Deposits",
    component: Deposits,
    children: [
      {
        path: "/deposits",
        name: "DepositsTable",
        component: DepositsTable,
      },
      {
        path: "/deposits/deposit/:id",
        name: "DepositsView",
        component: DepositsView,
      },
      {
        path: "/deposits/create-deposit",
        name: "DepositsCreate",
        component: DepositsCreate,
      },
    ],
  },
  // Transfers
  {
    path: "/transfers",
    name: "Transfers",
    component: Transfers,
    children: [
      {
        path: "/transfers",
        name: "TransfersTable",
        component: TransfersTable,
      },
      {
        path: "/transfers/cash_transfer",
        name: "CashTransfer",
        component: CashTransfer,
      },
      {
        path: "/transfers/metal_transfer",
        name: "MetalTransfer",
        component: MetalTransfer,
      },
      {
        path: "/transfers/:id",
        name: "TransfersView",
        component: TransfersView,
      },
    ],
  },
  // Gold Permission
  {
    path: "/permission",
    name: "Permission",
    component: GoldPermission,
    redirect: "/permission/gold-payment-permission",
    children: [
      // Gold Payment Permission
      {
        path: "/permission/gold-payment-permission",
        name: "GoldPaymentPermissionTable",
        component: GoldPaymentPermissionTable,
      },
      {
        path: "/permission/gold-payment-permission/:id",
        name: "GoldPaymentPermissionView",
        component: GoldPaymentPermissionCreate,
      },
      {
        path: "/permission/create-gold-payment-permission",
        name: "GoldPaymentPermissionCreate",
        component: GoldPaymentPermissionCreate,
      },
      // Gold Receipt Permission
      {
        path: "/permission/gold-receipt-permission",
        name: "GoldReceiptPermissionTable",
        component: GoldReceiptPermissionTable,
      },
      {
        path: "/permission/gold-receipt-permission/:id",
        name: "GoldReceiptPermissionView",
        component: GoldReceiptPermissionCreate,
      },
      {
        path: "/permission/create-gold-receipt-permission",
        name: "GoldReceiptPermissionCreate",
        component: GoldReceiptPermissionCreate,
      },
    ],
  },
  // Reports
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    redirect: "/reports/shift-report",
    children: [
      // Shift Reports
      {
        path: "/reports/shift-report",
        name: "ShiftReports",
        component: ShiftReports,
        children: [
          {
            path: "/reports/shift-report",
            name: "ShiftReportsTable",
            component: ShiftReportsTable,
          },
          {
            path: "/reports/shift-report/:id",
            name: "ShiftReportControl",
            component: ShiftReportControl,
          },
          // Customer Supplier Statement
          {
            path: "/reports/customer-supplier-statement",
            name: "CustomerSupplierStatementTable",
            component: CustomerSupplierStatementTable,
          },
          //  Supplier Statement
          {
            path: "/reports/supplier-statement",
            name: "SupplierStatementTable",
            component: SupplierStatementTable,
          },
          //  Sales notice report
          {
            path: "/reports/sales_notice_report",
            name: "FinalReportEntity",
            component: FinalReportEntity,
          },
          // full report
          {
            path: "/reports/full_report",
            name: "FullReportEntity",
            component: FullReportEntity,
          },
          // full report
          {
            path: "/reports/barcode_report",
            name: "BarcodeReportEntity",
            component: BarcodeReportEntity,
          },
        ],
      },
    ],
  },
];
