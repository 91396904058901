<template>
  <table id="printFile" :dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'" :class="pdfOrientation || 'portrait'">

    <thead id="fileHeader">
      <tr>
        <th style="padding: 10px 0 !important">
          <div style="display:flex; align-items: center; justify-content: space-between;"
            v-if="!header_slot && !customize_header_footer">
            <div>
              <h5>
                {{ $store.state.userData.user.branch_data.vat_register_name || ' ' }}
              </h5>
              <h6>
                {{ $t('vat number') }} : {{ $store.state.userData.user.branch_data.vat_number || ' ' }}
              </h6>
            </div>
            <div>
              <img :src="invoice_defaults?.branch_image" style="max-height: 70px;max-width: 100px;" alt="">
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody id="fileContent">
      <tr style="padding: 10px 0 !important">
        <td style="padding: 10px 0 !important">

          <v-row align="center" justify="center" v-if="customize_header_footer">
            <v-col cols="4">
              <div v-html="printStaticData.print_right" />
            </v-col>
            <v-col cols="4" class="logo">
              <img :src="printStaticData.logo" style="max-height: 50px;max-width: 100px;margin-right:20px" alt="">
            </v-col>
            <v-col cols="4" dir="ltr">
              <div v-html="printStaticData.print_left" />
            </v-col>
          </v-row>

          <div v-if="printTitle" style="text-align: center;">
            <h5> {{ printTitle }}</h5>
            <h6 v-if="printSubTitle"><small>{{ printSubTitle }}</small></h6>
            <div style="display:flex;padding : 0 10px" v-if="printSubTitleRight || printSubTitleLeft">
              <h6 style="width:50%;text-align: start;"><small>{{ printSubTitleRight }}</small></h6>
              <h6 style="width:50%;text-align: end;"><small>{{ printSubTitleLeft }}</small></h6>
            </div>
          </div>
          <slot name="pdf_header" />

          <!--  -->

          <v-simple-table :fixed-header="fixedHeader" id="tablePrint" style="margin: 10px 0 !important;display: block; "
            class="mt-3 d-none overflow-hidden" :height="data.length >= 10 ? tableHeight : 'auto'" v-if="!content_slot">
            <template v-slot:default>

              <thead :class="{ 'headerGrouped': showGroupHeader, 'headerIsHidden': headerIsHidden }">
                <tr v-if="showGroupHeader">
                  <th v-for="(group, groupIndex) in groupHeader.filter((head) => !head.hideInPrint)" :key="groupIndex"
                    :rowspan="group.rowspan" :colspan="group.colspan"
                    class="text-center group-header-th font-weight-bold">
                    {{ $t(group.text) | capitalize }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(header, index) in header.filter((head) => head.type !== 'actions' && !head.hideInPrint && !head.is_rowspan)"
                    class="text-center" :class="header.classes"
                    :style="{ 'min-width': (header.printWidth || header.width) + 'px', 'background-color': header.background }" :key="index">
                    <span>{{ $t(header.text) | capitalize }}</span>
                  </th>
                </tr>
              </thead>


    <tbody :class="{ 'group-header': showGroupHeader }">
      <template v-for="(row, index) in data">
        <slot name="beforeTR" :row="row" :isFirstRow="index == 0" :isLastRow="data.length == index + 1" :header="{}"
          :index="index" :colspan="header.filter((head) => !head.hideInPrint).length" />
        <tr :key="index" :class="row.rowClass" v-if="!row.isGroup">
          <td class="text-center"
            v-for="(header, headerIndex) in header.filter((head) => head.type !== 'actions' && !head.hideInPrint)"
            :style="{ 'min-width': (header.printWidth || header.width) + 'px', 'background-color': header.background }"
            :class="header.classes + ' ' + row.rowClass" :key="headerIndex"
            :dir="header.dir ? header.dir : $vuetify.rtl ? 'rtl' : 'ltr'">
            <!-- Index -->
            <span v-if="header.type == 'index'" print_att="index">{{ index + 1 }}</span>
            <!-- text || search -->
            <span v-else-if="header.printType == 'text' || header.type == 'text' || header.type == 'search'"
              print_att="text">
              {{ row[header.printKey || header.key] || '-' }}</span>
            <!-- i18n -->
            <span v-else-if="header.printType == 'i18n' || header.type == 'i18n'">
              {{ $t(row[header.printKey ||header.key]) || '-' }}</span>
            <!-- number -->
            <span v-else-if="header.printType == 'number' || header.type == 'number'" print_att="number">{{
              row[header.key] || 0 }}</span>
            <!-- float -->
            <span v-else-if="header.printType == 'float' || header.type == 'float'" print_att="float">
              {{ row[header.key] | nonZero }}
            </span>
            <!-- non_zero_float -->
            <div v-else-if="header.printType == 'non_zero_float' || header.type == 'non_zero_float'" dir="ltr"
              :class="{ 'error--text': row[header.key] < 0 }">
              <template v-if="row.isCredit && row[header.key] > 0" print_att="non_zero_float">
                ({{ row[header.key] | nonZero }})
              </template>
              <template v-else>
                {{ row[header.key] | nonZero }}
              </template>
            </div>
            <!-- boolean -->
            <span v-else-if="header.printType == 'boolean' || header.type == 'boolean'">
              {{ row[header.key] ? $t('yes') : $t('no') }}
            </span>
            <!-- percentage -->
            <span v-else-if="header.printType == 'percentage' || header.type == 'percentage'" print_att="percentage">{{
              row[header.key]?.toFixed(2) }} %</span>
            <!-- price -->
            <span v-else-if="header.printType == 'price' || header.type == 'price'" print_att="price">
              {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
              {{ $t('sar') }}
            </span>
            <!-- weight -->
            <span v-else-if="header.printType == 'weight' || header.type == 'weight'" print_att="weight">
              {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
              {{ $t('gram') }}
            </span>
            <!-- pieces  -->
            <span v-else-if="header.printType == 'pieces' || header.type == 'pieces'" print_att="pieces">{{
              row[header.key] || '-'
              }} {{ $t('pieces')
              }}</span>
            <!-- img  -->
            <span v-else-if="header.type == 'img'">
              <v-avatar size="24" class="rounded-0" v-lazy-container="{ selector: 'img' }">
                <img v-if="row[header.key]" :data-src="$store.state.endpointURL + row[header.key]"
                  style="max-height: 24px;" alt="" />
              </v-avatar>
            </span>
            <!-- img base -->
            <span v-else-if="header.type == 'img_base64'">
              <v-avatar size="24" class="rounded-0">
                <v-img v-if="row[header.key]" :src="row[header.key]" alt="" />
                <v-img v-if="!row[header.key]" src="@/assets/img/png/files/notFound.png" alt="" />
              </v-avatar>
            </span>
            <!-- array -->
            <span v-else-if="header.type == 'array' && row[header.key]">
              <div v-for="(text, index) in row[header.key].split(',').map(i => i)" :key="index" class="mx-1">
                {{ text }}
              </div>
            </span>
            <!-- imgs -->
            <span v-else-if="header.type == 'imgs' && row[header.key]">
              <v-avatar size="35" class="mr-n3 rounded-circle  white"
                v-for="(img, index) in row[header.key].split(',').map(i => i)" :key="index">
                <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                    <v-img v-bind="attrs" v-on="on" :src="$store.state.endpointURL + img" alt="" />
                  </template>
                  <span v-if="row.users_names">{{ row.users_names.split(',').map(i => i)[index] }}</span>
                </v-tooltip>
              </v-avatar>
            </span>
            <!-- slot -->
            <span v-else-if="header.type == 'slot' && !header.printType" class="d-flex justify-center">
              <slot name="td" :row="row" :header="header" :index="index"></slot>
            </span>
            <!-- date -->
            <span v-else-if="header.type == 'date'" print_att="date"> {{ row[header.key] || '-' }}</span>
            <span v-else>-</span>
          </td>
        </tr>
        <slot name="afterTR" :row="row" :isFirstRow="index == 0" :isLastRow="data.length == index + 1" :header="{}"
          :index="index" :colspan="header.filter((head) => !head.hideInPrint).length" />
      </template>
      <!-- Footer -->
      <tr v-for="(row, index) in footerData" :key="index">
        <th v-for="(header, headerIndex) in header.filter((head) => !head.hideInPrint)" :class="header.classes"
          :dir="header.dir ? header.dir : $vuetify.rtl ? 'rtl' : 'ltr'" :style="{ 'min-width': header.width + 'px' }"
          :key="headerIndex">
          <!-- index -->
          <span v-if="header.type == 'index'" class="font-weight-bold " print_att="index">{{ row[header.key] || '-'
            }}</span>
          <!-- text -->
          <span v-if="header.type == 'text'" class="font-weight-bold " print_att="text">{{ row[header.key] || '-'
            }}</span>
          <!-- i18n -->
          <span v-else-if="header.type == 'i18n'" class="font-weight-bold ">{{ $t(row[header.key]) || '-' }}</span>
          <!-- float -->
          <span v-else-if="header.type == 'float'" class="font-weight-bold" print_att="float">
            {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
          </span>
          <!-- non_zero_float -->
          <div v-else-if="header.type == 'non_zero_float'" print_att="non_zero_float" dir="ltr"
            :class="{ 'error--text': row[header.key] < 0 }">
            {{ row[header.key] | nonZero }}
          </div>
          <!-- price -->
          <span v-else-if="header.type == 'price'" class="font-weight-bold" print_att="price">
            {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
            {{ $t('sar') }}</span>
          <!-- weight -->
          <span v-else-if="header.type == 'weight'" class="font-weight-bold" print_att="weight">
            {{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2)) || '-' }}
            {{ $t('gram') }}</span>
          <!-- pieces  -->
          <span v-else-if="header.type == 'pieces'" class="font-weight-bold" print_att="pieces">
            {{ row[header.key] || '-' }} {{ $t('pieces') }}
          </span>
          <!-- slot -->
          <span v-if="header.type == 'slot'" class="font-weight-bold ">{{ row[header.key] || '-' }}</span>

          <!-- date -->
          <span v-else-if="header.type == 'date'" class="font-weight-bold" print_att="date">
            <span v-if="row[header.key]"> {{ row[header.key] }}</span>
            <span v-else>-</span>
          </span>

          <span v-else></span>
        </th>
      </tr>
    </tbody>

</template>
</v-simple-table>
<slot name="pdf_footer" />


<!--  -->
</td>
</tr>
</tbody>
<tfoot id="fileFooter">
  <tr>
    <th>
      <slot name="footer" />
      <footer v-if="!header_slot && !customize_header_footer">
        <h6>
          {{ application_name }}
          <div><small>{{ application_brief }}.</small></div>
        </h6>
        <h6>
          {{ $store.state.userData.full_name || ' ' }}
          <!-- <div><small>{{ $t('branch') }}: {{ getActiveBranch || ' '}}</small></div>  -->
          <div><small>{{ $global.GetCurrentDate() }} {{ $global.GetCurrentTime() }}</small></div>
        </h6>
      </footer>
    </th>
  </tr>
</tfoot>
</table>
</template>PrintTable



<script>

export default {
  name: "PrintTable",
  props: {
    size: { default: 'a4' },
    data: { default: [] },
    header: { default: [] },
    footerData: { default: null },
    header_slot: { default: false },
    watermark: { default: false },
    content_slot: { default: false },
    customize_header_footer: { default: false },
    footer_slot: { default: false },
    showGroupHeader: { default: false },
    printTitle: { default: null },
    printSubTitle: { default: null },
    printSubTitleLeft: { default: null },
    printSubTitleRight: { default: null },
    groupHeader: { default: null },
    fixedHeader: { default: true },
    headerIsHidden: { default: false },
    maxPDFRows: { default: 12 },
    printStaticData: { default: Object },
    pdfOrientation: { default: 'portrait' },
    tableHeight: {
      default() {
        return this.$store.state.Settings.windowSize.y - 220
      },
    },
  },
  data: () => ({
    application_name: '',
    application_brief: '',
    invoice_defaults : {}
  }),
  mounted() {
    this.invoice_defaults = this.$store.state.userData?.user?.branch_data
    this.application_name = this.$i18n.t('qarat erp system');
    this.application_brief = this.$i18n.t('program for managing gold and jewelry stores');
  },
  computed: {

  },
  methods: {},
  components: {},
};
</script>

<style lang="scss">
#printFile {
  &:not(.view) {
    position: absolute;
    top: -1000px;
    z-index: -1;

    @media print {
      position: relative;
      top: unset;
      z-index: 2;
      width: 100%;
      border-collapse: collapse;
      word-wrap: break-word;
      table-layout: fixed;
      width: 100%;
    }
  }

  &.portrait {
    @media print {
      size: A4 portrait !important
    }
  }
  &.landscape {
    @media print {
      size: A4 landscape !important
    }
  }
  #tablePrint {
    border-radius: 0 !important;
    border: 1px solid 0#000 !important;

    tfoot {
      position: sticky !important;
      bottom: 0;
    }

    .headerIsHidden {
      opacity: 0;
    }

    th {

      background-color: #ddd !important;
      padding: 2px !important;
      font-weight: 500;
      height: auto;
      color: #000 !important;
      position: relative;
      top: 0;
      box-shadow: inset 1px 1px black;

      &:first-of-type {
        border-right: 1px solid #000 !important;
      }

      &:last-of-type {}
    }

    tr:nth-of-type(even) {
      background-color: unset !important;

    }

    tr {
      margin: 0 !important;
      box-shadow: inset 1px 1px black;

      &:last-of-type {}
    }

    td {
      //  border-right: 0.3px solid #000 !important;
      border-bottom: 0.3px solid #000 !important;
      box-shadow: inset 1px 1px black;
      background-color: transparent !important;
      padding: 2px !important;
      font-weight: 500;
      color: #000 !important;
      height: auto;


      &:nth-of-type(1) {
        box-shadow: inset 1px 1px black;
        background: #eee !important;
        border-right: 1px solid #000 !important;
      }

      &:last-of-type {
        box-shadow: inset 1px 1px black;
      }

      span {
        font-family: "times_new_roman" !important;
        display: flex !important;
        flex-wrap: wrap !important;
        width: 100% !important;
        align-items: center !important;
        justify-content: center !important;
        overflow: hidden !important;
      }

      // span[print_att="float"],
      // span[print_att="weight"],
      // span[print_att="price"],
      // span[print_att="date"],
      // span[print_att="non_zero_float"]{
      //   font-family: "arial" !important;
      // }

    }
  }

  #fileHeader {

    tr,
    th {
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent !important;
    }
  }

  #fileFooter {

    footer {
      margin-top: 5px;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }

    tr,
    th {
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent !important;
    }
  }
}
</style>