<template>
  <span>
    <div v-show="false" v-print="printObj" id="printObjRow"></div>
    <div id="printContent">
      <PrintTable :tableHeight="'auto'" :header="header" :maxPDFRows="maxPDFRows" :printTitle="printTitle"
        :printSubTitle="printSubTitle" :printSubTitleLeft="printSubTitleLeft" :printSubTitleRight="printSubTitleRight"
        :customize_header_footer="customize_header_footer" :data="exportData || []" :printStaticData="printStaticData"
        :header_slot="header_slot" :footer_slot="footer_slot" :showGroupHeader="groupHeader.length"
        :groupHeader="groupHeader" :footerData="footerData || []" :pdfOrientation="pdfOrientation">
        <template v-slot:pdf_header>
          <slot name="pdf_header" />
        </template>
        <template v-slot:after_table>
          <slot name="after_table" />
        </template>
        <template v-slot:pdf_footer>
          <slot name="pdf_footer" />
        </template>
        <template v-slot:td="{ row, header, index }">
          <slot name="td" :row="row" :header="header" :index="index"></slot>
        </template>
      </PrintTable>
    </div>


  </span>
</template>


<script>
import PrintTable from "./PrintTable.vue";
import Vue from "vue";
// Register v-print directive globally if not already registered
Vue.directive('print', window['v-print']);
export default {
  name: "ExportBtnNBNow",
  props: {
    btnText: { default: 'print' },
    width: { default: 130 },
    print: { default: false },
    pdf: { default: true },
    excel: { default: false },
    image: { default: true },
    disabled: { default: false },
    loading: { default: false },
    openOnHover: { default: true },
    classes: { default: " backgroundW black--text shadow" },
    exportData: { default: [] },
    footerData: { default: [] },
    header: { default: [] },
    attachmentFileName: { default: 'مرفق' },
    printTitle: { default: null },
    printSubTitle: { default: null },
    printSubTitleLeft: { default: null },
    printSubTitleRight: { default: null },
    maxPDFRows: { default: 48 },
    groupHeader: { default: [] },
    showGroupHeader: { default: false },
    customize_header_footer: { default: false },
    printStaticData: { default: Object },
    header_slot: { default: false },
    footer_slot: { default: false },
    pdfOrientation: { default: 'portrait' },
  },
  data: () => ({
    printLoading: false,
  }),
  computed: {
    printObj() {
      return {
        id: "printContent",
        popTitle: null,
        previewTitle: null,
        previewPrintBtnLabel: null,
        preview: false,
      }
    }
  },
  watch: {
    print() {
      if (this.print) {
        let printObj = document.getElementById("printObjRow");
        printObj.click();
      }
    },
    excel() {
      if (this.excel) {
        this.exportExcel()
      }
    }
  },
  components: {
    PrintTable,
  },
  mounted() {
    const style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `
        @media print {
          @page {size: A4 ${this.pdfOrientation};}
        }
      `;
      document.head.appendChild(style);
  },
  beforeDestroy: function () {

  },
  methods: {
    exportPDF() {
      this.$refs.html2Pdf.generatePdf();
      this.printLoading = true
      setTimeout(() => { // fake loader
        this.printLoading = false;
      }, this.exportData > 500 ? 5000 : 2000);
    },
    exportExcel() {
      this.printLoading = true
      setTimeout(() => { // fake loader
        this.printLoading = false;
      }, this.exportData > 500 ? 1000 : 500);

      this.$global.ExportExcel(
        [...this.exportData],
        [...this.header],
        [...this.footerData] || [],
        this.$i18n.t(this.attachmentFileName)
      )
    },
  },
};
</script>



<style lang="scss">

#printContent {
  &:not(.view) {
    position: absolute;
    top: -1000px;
    z-index: -1;

    @media print {
      top: unset;
      z-index: 2;
    }
  }


  #fileHeader {

    tr,
    th {
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent !important;
    }
  }

  #fileFooter {


    tr,
    th {
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent !important;
    }
  }
}
</style>
