<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="950">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded-md color="primary" v-bind="attrs" v-on="on" depressed>
          <span>{{ activeType == 3 ? $t('select supplier') : $t('select customer') }}</span>
        </v-btn>
      </template>
      <v-card relative>
        <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="dialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6">{{ activeType == 3 ? $t('select supplier') : $t('select customer') }}
        </v-card-title>
        <v-divider></v-divider>

        <!-- Customer Type -->


        <v-btn-toggle :value="activeType" tile class="d-block" group>
          <v-btn :value="1" v-on:click="() => activeType = 1" class="mx-2 rounded-pill" elevation="0">
            <v-icon color="primary" :disabled="activeType != 1" left>mdi-check-circle</v-icon>
            {{ $t('cash customer') }}
          </v-btn>
          <v-btn :value="2" v-on:click="() => activeType = 2" class="mx-2 rounded-pill" elevation="0">
            <v-icon color="primary" :disabled="activeType != 2" left>mdi-check-circle</v-icon>
            {{ $t('late invoice for customer') }}
          </v-btn>
          <v-btn :value="3" v-on:click="() => activeType = 3" class="mx-2 rounded-pill" elevation="0">
            <v-icon color="primary" :disabled="activeType != 3" left>mdi-check-circle</v-icon>
            {{ $t('late invoice for supplier') }}
          </v-btn>
        </v-btn-toggle>

        <!-- search -->
        <v-col cols="12" class="d-flex align-center">
          <v-text-field class="my-2" outlined
            :placeholder="activeType == 3 ? $i18n.t('supplier name / mobile number') : $i18n.t('customer name / mobile number')"
            v-model="search" @keydown.enter="searchReturnSales" rounded-md dense autofocus hide-details type="text"
            @click="searchResult = [], viewForm = false"></v-text-field>

          <v-btn @click="searchReturnSales" :disabled="loading" :loading="loading" class="my-2 mx-2 shadow"
            color="success">
            <v-icon dark>mdi-magnify</v-icon>
            <span class="d-md-block d-none">{{ $t('search') }}</span>
          </v-btn>
          <v-btn @click="viewForm = true, search = ''" :disabled="viewForm" class="my-2 mx-2 shadow" color="primary">
            <v-icon dark>mdi-plus-thick</v-icon>
            <span class="d-md-block d-none">{{ activeType == 3 ? $t('add new supplier') : $t('add new customer')
              }}</span>
          </v-btn>
        </v-col>
        <!-- Search result -->

        <v-col cols="12" sm="12" v-if="searchResult.length > 0 && !viewForm">
          <v-card class="backgroundW shadow pa-5">
            <v-simple-table :height="this.$store.state.Settings.windowSize.y - 350">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ customerType == 2 ? $t('supplier name') : $t('customerName') }}</th>
                    <th class="text-center">{{ customerType == 2 ? $t('supplier mobile') : $t('customerMobile') }}</th>
                    <th class="text-center">{{ $t('Identification Number') }}</th>
                    <th class="text-center">{{ $t('Registration tax number') }}</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in searchResult" :key="index">
                    <td>{{ row.name }}</td>
                    <td>{{ row.mobile }}</td>
                    <td>{{ row.id_number }}</td>
                    <td>{{ row.vat_register_number }}</td>
                    <td class="d-flex align-center">
                      <v-btn color="info" outlined class="mx-1 mt-1" @click="customerWithdrawals(row.id)" small>
                        <img height="15" class="mx-1" src="@/assets/img/svg/withdrawals.svg" alt="" />
                        {{ $t('withdrawals') }}
                      </v-btn>
                      <v-btn color="success" width="100" depressed class="mx-1 mt-1" @click="returnData(row)" small>
                        {{ $t('choose') }}
                        <v-icon right size="14">mdi-check</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <!-- Form -->
        <v-col cols="12" sm="12" v-if="viewForm">
          <v-card elevation="0" class=" pa-0">
            <v-form ref="form" v-model="valid">
              <v-row align="center" justify="end">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field outlined v-model="form.name"
                    :placeholder="activeType == 3 ? $i18n.t('supplier name') : $i18n.t('customerName')"
                    :rules="$store.state.Settings.requiredRule" rounded-md dense type="text"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field outlined v-model="form.mobile"
                    :placeholder="activeType == 3 ? $i18n.t('supplier number') : $i18n.t('customerMobile')" rounded-md
                    dense type="tel"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0" v-if="activeType > 1">
                  <v-text-field outlined v-model="form.idNumber" :placeholder="$i18n.t('Identification Number')"
                    rounded-md dense type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0" v-if="activeType > 1">
                  <v-text-field outlined v-model="form.vat_register_number"
                    :placeholder="$i18n.t('Registration tax number')" :rules="$store.state.Settings.requiredRule"
                    rounded-md dense type="email"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="py-0" v-if="activeType > 1">
                  <v-textarea outlined v-model="form.address" :placeholder="$i18n.t('Address')" rounded-md dense
                    type="email"></v-textarea>
                </v-col>
                <v-col cols="12" sm="auto">
                  <v-btn color="success" :min-width="100" :disabled="!valid" :loading="loading" @click="addCustomer"
                    class="shadow">{{ $t('save') }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>

        <v-col cols="auto" v-if="loading">
          <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
          <p class="text-center">{{ $t('Loading data') }}</p>
        </v-col>
      </v-card>
    </v-dialog>
    <CustomerWithdrawals :closeDialog="closeWithdrawalsDialog" :withdrawalsDialog="withdrawalsDialog" />
  </v-row>
</template>



<style scoped lang="scss"></style>



<script>
import { createNamespacedHelpers } from "vuex";
import CustomerWithdrawals from "./CustomerWithdrawals.vue";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "CustomerModal",
  props: {
    backValueMethod: { type: Function },
    customer: { default: Object },
    supplier: { default: Object },
  },
  computed: {
    ...mapState(["customerType"]),
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  watch: {
    customerType() {
      this.searchResult = [];
      this.form = {
        name: "",
        mobile: "",
        idNumber: "",
        email: "",
        address: "",
        vat_register_number: "",
      };
    },
    dialog() {
      this.viewForm = false;
    },
    activeType() {
      switch (this.activeType) {
        case 1: this.changeCustomerType(1); break;
        case 2: this.changeCustomerType(1); break;
        default: this.changeCustomerType(2); break;
      }
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      search: "",
      activeType: 1,
      searchResult: [],
      viewForm: false,
      withdrawalsDialog: {
        dialog: false,
        customer_id: null,
        supplier_id: null,
      },
      form: {
        name: "",
        mobile: "",
        idNumber: "",
        email: "",
        address: "",
        vat_register_number: "",
      },
    };
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyC") {
        this.dialog = true;
      }
      if (e.code == "Escape") {
        this.dialog = false;
      }
    });
  },
  methods: {
    ...mapActions(["changeCustomerType"]),
    returnData(id) {
      this.backValueMethod(id);
      this.dialog = false;
      this.viewForm = false;
    },
    searchReturnSales() {
      this.loading = true;
      let endpoint = this.customerType == 2 ? "search_supplier" : "search_customer";
      this.$api.GET_METHOD(`${endpoint}?word=${this.search}&type=${this.activeType == 3 ? '' : this.activeType}`).then((response) => {
        this.loading = false;
        this.searchResult = response.data;
      });
    },
    addCustomer() {
      this.loading = true;
      this.form.type = this.activeType;
      let endpoint = this.customerType == 2 ? "add_supplier" : "add_customer";
      this.$api.POST_METHOD(`${endpoint}`, this.form, true, this.$i18n.t("Created Successfully")).then((response) => {
        this.loading = false;
        if (response.check) {
          if (this.customerType == 2) {
            this.backValueMethod(response.data.supplier);
          }
          else {
            this.backValueMethod(response.data[0]);
          }
          this.form = { name: "", mobile: "", idNumber: "", email: "", address: "", vat_register_number: "" };
          this.dialog = false;
          this.viewForm = false;
        }
      });
    },
    customerWithdrawals(id) {
      this.withdrawalsDialog = {
        dialog: true,
        customer_id: this.customerType == 1 ? id : null,
        supplier_id: this.customerType == 2 ? id : null,
      };
    },
    closeWithdrawalsDialog() {
      this.withdrawalsDialog = {
        dialog: false,
        customer_id: null,
        supplier_id: null,
      };
    }
  },
  components: { CustomerWithdrawals }
};
</script>
