<template>
   <section>
      <v-container fluid>

         <!-- Money & Gold Transactions -->
         <v-card flat class="shadow pa-3 mt-5">
            <v-row>
               <v-col cols="12" class="d-flex pb-0 justify-end font-weight-bold align-center subtitle-2">
                  <div class="gray7--text">
                     {{ $t('shift start') }} :
                     <span class="primary--text">{{ selectedShift.start_date }}</span>
                  </div>
                  <v-icon class="mx-2 mt-n1" size="20">
                     {{ $i18n.locale == 'ar' ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold' }}</v-icon>
                  <div class="gray7--text">
                     {{ $t('shift end') }} :
                     <span class="primary--text">{{ selectedShift.end_date }}</span>
                  </div>
                  <v-btn class="ms-2" color="success" small depressed @click="() => print = true">{{ $t('print') }}</v-btn>
               </v-col>
               <!-- Sales Cash -->
               <v-col cols="12" md="6">
                  <v-card outlined class="pa-3 pb-0">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/cash_transactions.svg" alt="qarat" class="me-1" />
                        <div class="pt-0"> {{ $t('Sales cash') }}</div>
                     </h6>
                     <DynamicTable :showHeader="false" :showExtensions="false" :data="moneyTransactions"
                        :header="tableHeader" :tableHeight="410"
                        :footerData="[calculatedTotalsParams(tableHeader, moneyTransactions)]" />
                  </v-card>
               </v-col>
               <!-- Gold -->
               <v-col cols="12" md="6">
                  <v-card outlined class="pa-3 pb-16">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/gold_transactions.svg" alt="qarat" class="me-1" />
                        <div class="pt-0"> {{ $t('gold transaction') }}</div>
                     </h6>
                     <DynamicTable :showHeader="false" :showExtensions="false" :data="usedTransactions"
                        :header="tableHeader" :tableHeight="500"
                        :footerData="[calculatedTotalsParams(tableHeader, usedTransactions)]" />
                  </v-card>
               </v-col>
            </v-row>
         </v-card>
         <!-- Actual in the drawer & Notice Transactions -->
         <v-card flat class="shadow pa-3 mt-3">
            <v-row>
               <!-- Actual in the drawer  -->
               <v-col cols="12" md="9">
                  <v-card outlined class="pa-3">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/vouchers.svg" alt="qarat" class="me-1" />
                        <div class="pt-2"> {{ $t('actual in the drawer') }}</div>
                     </h6>
                     <v-row class="mt-2">
                        <v-col v-for="( item, index) in drawer" :key="index">
                           <v-card color="light" flat class="subtitle-2 rounded-0 text-center pa-3">
                              <img :src="item.icon" :alt="item.title" />
                              <div class="font-weight-bold">{{ $t(item.title) }}</div>
                              <div class="mt-1">{{ item.value | float }} {{ $t(item.unit) }}</div>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-col>
               <!-- Notice Transactions -->
               <v-col cols="12" md="3">
                  <v-card outlined class="pa-3">
                     <v-card color="red11" flat height="76"
                        class="red4--text d-flex justify-center align-center subtitle-1 pa-2">
                        <v-icon class="me-2 mt-n1" size="20" color="red4">mdi-minus-circle-outline</v-icon>
                        <div class="me-2 font-weight-bold">{{ $t('notice creditor') }}</div>
                        <div>{{ report?.drawer?.final_nc || 0 | float }}</div>
                     </v-card>
                     <v-card color="green11" flat height="76"
                        class="green1--text d-flex justify-center align-center subtitle-1 pa-2 mt-2">
                        <v-icon class="me-2 mt-n1" size="20" color="green1">mdi-plus-circle-outline</v-icon>
                        <div class="me-2 font-weight-bold">{{ $t('notice debtor') }}</div>
                        <div>{{ report?.drawer?.final_nd || 0 | float }}</div>
                     </v-card>
                  </v-card>
               </v-col>
            </v-row>
         </v-card>
         <!-- Sales & Purchase  -->
         <v-card flat class="shadow pa-3 mt-3">
            <v-row>
               <!-- Sales -->
               <v-col cols="12" md="8">
                  <v-card outlined class="pa-3">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/sales.svg" alt="qarat" class="me-1" />
                        <div class="pt-2"> {{ $t('total sales') }}</div>
                     </h6>
                     <v-row class="mt-2 pa-3">
                        <v-col v-for="( item, index) in totalSales" :key="index" class="pa-0">
                           <v-card :color="index % 2 ? 'light' : 'backgroundW'" flat
                              class="subtitle-2 rounded-0 text-center pa-3">
                              <div class="font-weight-bold">{{ $t(item.title) }}</div>
                              <div class="mt-1">{{ item.value || 0 | nonZero }}</div>
                              <div>{{ $t(item.unit) }}</div>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-col>
               <!-- Purchase -->
               <v-col cols="12" md="4">
                  <v-card outlined class="pa-3">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/purchase.svg" alt="qarat" class="me-1" />
                        <div class="pt-2">{{ $t('total purchase') }}</div>
                     </h6>
                     <v-row class="mt-2 pa-3">
                        <v-col v-for="( item, index) in totalPurchases" :key="index" class="pa-0">
                           <v-card :color="index % 2 ? 'light' : 'backgroundW'" flat
                              class="body-2 rounded-0 text-center pa-3">
                              <div class="font-weight-bold">{{ $t(item.title) }}</div>
                              <div class="mt-1">{{ item.value | nonZero }}</div>
                              <div>{{ $t(item.unit) }}</div>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-col>
            </v-row>
         </v-card>
         <!-- Supplier Payments & Deposits & Expenses  -->
         <v-card flat class="shadow pa-3 mt-3">
            <v-row>
               <!-- Supplier Payments -->
               <v-col cols="12" md="8">
                  <v-card outlined class="pa-3">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/vouchers.svg" alt="qarat" class="me-1" />
                        <div class="pt-2">{{ $t('supplier payments') }}</div>
                     </h6>
                     <v-row class="mt-2 pa-3" justify="center">
                        <v-col v-for="( item, index) in supplierPayments" :key="index" class="pa-0 mt-3 mb-1" cols="12"
                           md="3">
                           <v-card :color="index % 2 ? 'light' : 'backgroundW'" flat
                              class="subtitle-2 rounded-0 text-center pa-3">
                              <div class="font-weight-bold">{{ $t(item.title) }}</div>
                              <div class="mt-1">{{ item.value | nonZero }}</div>
                              <div>{{ $t(item.unit) }}</div>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-col>
               <v-col cols="12" md="4">
                  <!-- Deposits -->
                  <v-card outlined class="pa-3">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/deposits.svg" alt="qarat" class="me-1" />
                        <div class="pt-2">{{ $t('total deposits') }}</div>
                     </h6>
                     <v-row class="mt-2 pa-3">
                        <v-col v-for="( item, index) in totalDeposits" :key="index" class="pa-0">
                           <v-card min-height="80px" :color="index % 2 ? 'light' : 'backgroundW'" flat
                              class="body-2 d-flex align-center rounded-0 text-center pa-3">
                              <div>
                                 <div class="font-weight-bold">{{ $t(item.title) }}</div>
                                 <div class="mt-1">{{ item.value | nonZero }}</div>
                                 <div>{{ $t(item.unit) }}</div>
                              </div>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
                  <!-- Expenses -->
                  <v-card outlined class="pa-3 mt-4">
                     <h6 class="subtitle-1 font-weight-bold d-flex align-center">
                        <img src="@/assets/img/svg/expenses.svg" alt="qarat" class="me-1" />
                        <div class="pt-2">{{ $t('total expenses') }}</div>
                     </h6>
                     <v-row class="mt-2 pa-3">
                        <v-col v-for="( item, index) in totalExpenses" :key="index" class="pa-0">
                           <v-card min-height="80px" :color="index % 2 ? 'light' : 'backgroundW'" flat
                              class="body-2 d-flex align-center rounded-0 text-center pa-3">
                              <div>
                                 <div class="font-weight-bold">{{ $t(item.title) }}</div>
                                 <div class="mt-1">{{ item.value | nonZero }}</div>
                                 <div>{{ $t(item.unit) }}</div>
                              </div>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-col>
            </v-row>
         </v-card>



      </v-container>




      <ExportBtnNBNow :exportData="[]" :header="[]" :footerData="[]" :groupHeader="[]" :print="print" :printTitle="$t('shift report')"
         pdfOrientation="landscape">
         <template v-slot:pdf_header>
            <DailyTransactionsPrint :tableHeader="tableHeader" :moneyTransactions="moneyTransactions"
               :usedTransactions="usedTransactions" :drawer="drawer" :totalSales="totalSales"
               :totalPurchases="totalPurchases" :supplierPayments="supplierPayments" :totalDeposits="totalDeposits"
               :totalExpenses="totalExpenses" :from_date="selectedShift.from" :to_date="selectedShift.to"
               :selectedShift="selectedShift" :printWithTransactions="printWithTransactions"
               :user_name="selectedShift.user_full_name" :shiftTransactions="shiftTransactions" />
         </template>
      </ExportBtnNBNow>

   </section>
</template>

<script>
import ExportBtnNBNow from '@/components/ui/ExportBtnNBNow.vue';
import DailyTransactionsPrint from './DailyTransactionsPrint.vue';
import DynamicTable from '@/components/DynamicTable.vue';


export default {
   name: "ShiftSummery3",
   props: {
      id: { default: 0 },
      report_data: { default: {} },
      shift: { default: {} },
      auto_print: { default: true },
   },
   data: () => ({
      report: {},
      branches: [],
      shifts: [],
      branch_id: 0,
      shift_id: 0,
      fetched: false,
      isLoading: false,
      print: false,
      selectedRow: {},
      transactionsDialog: false,
      printWithTransactions: 0,
      shiftTransactions: [],
   }),
   mounted() {
      this.shift_id = +this.$route.query.id || this.id || null;
      this.report = this.$route.params.data || this.report_data || {};
      this.print = this.auto_print
   },
   watch: {
      print() {
         setTimeout(() => {
            this.print = false;
         }, 100);
      }
   },
   components: { DailyTransactionsPrint, ExportBtnNBNow, DynamicTable },
   computed: {
      selectedShift() {
         return this.$route.params.shift || this.shift;
      },
      tableHeader() {
         return [
            { text: "", key: "title", type: 'i18n', classes: "font-weight-bold" },
            { text: "start", key: "start", type: 'non_zero_float' },
            { text: "Transactions", key: "trans", type: 'non_zero_float' },
            { text: "end", key: "end", type: 'non_zero_float' },
            { text: "difference", key: "diff", type: 'non_zero_float' },
         ]
      },
      calculatedTotalsParams() {
         return (header = [], data = []) => {
            const HEADER = header?.length ? header : this.tableHeader;
            const DATA = data?.length ? data : this.pageData.rows;
            const CALCULATE_TOTAL = (property) => DATA.reduce((prevValue, row) => {
               return prevValue + row[property];
            }, 0);
            let totalObj = {};
            HEADER.forEach((header, index) => {
               switch (header.type) {
                  case 'float':
                  case 'non_zero_float':
                  case 'weight':
                  case 'number':
                  case 'price':
                     totalObj[header.key] = CALCULATE_TOTAL(header.key);
                     break;
                  default:
                     totalObj[header.key] = !index ? this.$t('total') : '';
                     break;
               }
            });
            totalObj.sub_category_full = DATA.length + ' ' + this.$t('item');
            return totalObj;
         }
      },
      moneyTransactions() {
         const { cash = {}, mada = {}, master_card = {}, other = {}, tabby = {}, stc = {},
            tamara = {}, nearpay = {}, transfer = {}, visa = {}, salla = {} } = this.report?.cash_transactions || {};
         const transactions = [
            { title: 'cash', transaction_type: 'cash', ...cash },
            { title: 'master card', transaction_type: 'master', ...master_card },
            { title: 'visa', transaction_type: 'visa', ...visa },
            { title: 'mada', transaction_type: 'mada', ...mada },
            { title: 'transfer', transaction_type: 'trans', ...transfer },
            { title: 'tabby', transaction_type: 'tabby', ...tabby },
            { title: 'STC Pay', transaction_type: 'stc', ...stc },
            { title: 'nearpay', transaction_type: 'nearpay', ...nearpay },
            { title: 'tamara', transaction_type: 'tamara', ...tamara },
            { title: 'salla', transaction_type: 'salla', ...salla },
            { title: 'other', transaction_type: 'other', ...other },
         ];
         return transactions;
      },
      usedTransactions() {
         const { used_18 = {}, used_21 = {}, used_22 = {}, used_24 = {}, } = this.report?.gold_transactions || {};
         const { closing_18 = {}, closing_21 = {}, closing_22 = {}, closing_24 = {} } = this.report?.gold_transactions || {};
         return [
            { title: 'used 18', transaction_type: 'used_weight_18', ...used_18 },
            { title: 'used 21', transaction_type: 'used_weight_21', ...used_21 },
            { title: 'used 22', transaction_type: 'used_weight_22', ...used_22 },
            { title: 'used 24', transaction_type: 'used_weight_24', ...used_24, rowClass: 'border-bottom-gray' },
            { title: 'closing 18', transaction_type: 'cp_weight_18', ...closing_18 },
            { title: 'closing 21', transaction_type: 'cp_weight_21', ...closing_21 },
            { title: 'closing 22', transaction_type: 'cp_weight_22', ...closing_22 },
            { title: 'closing 24', transaction_type: 'cp_weight_24', ...closing_24 },
         ]
      },
      drawer() {
         const { final_gold_18, final_gold_21, final_gold_22, final_gold_24, final_cash } = this.report?.drawer || {};
         return [
            { title: 'caliber 18', value: final_gold_18 || 0, unit: 'gram', icon: require('@/assets/img/svg/caliber_18.svg') },
            { title: 'caliber 21', value: final_gold_21 || 0, unit: 'gram', icon: require('@/assets/img/svg/caliber_21.svg') },
            { title: 'caliber 22', value: final_gold_22 || 0, unit: 'gram', icon: require('@/assets/img/svg/caliber_22.svg') },
            { title: 'caliber 24', value: final_gold_24 || 0, unit: 'gram', icon: require('@/assets/img/svg/caliber_24.svg') },
            { title: 'cash', value: final_cash || 0, unit: 'ryal', icon: require('@/assets/img/svg/cash.svg') },
         ]
      },
      totalSales() {
         const { total_sales_cash, total_sales_mada, total_sales_master, total_sales_other,
            total_sales_tabby, total_sales_stc, total_sales_tamara, total_sales_nearpay, total_sales_trans, total_sales_visa, total_sales_salla } = this.report?.sales || {};
         return [
            { title: 'cash', value: total_sales_cash, transaction_type: 'cash' },
            { title: 'master card', value: total_sales_master, transaction_type: 'master' },
            { title: 'visa', value: total_sales_visa, transaction_type: 'visa' },
            { title: 'mada', value: total_sales_mada, transaction_type: 'mada' },
            { title: 'transfer', value: total_sales_trans, transaction_type: 'trans' },
            { title: 'tabby', value: total_sales_tabby, transaction_type: 'tabby' },
            { title: 'nearpay', value: total_sales_nearpay, transaction_type: 'nearpay' },
            { title: 'STC Pay', value: total_sales_stc, transaction_type: 'stc' },
            { title: 'tamara', value: total_sales_tamara, transaction_type: 'tamara' },
            { title: 'salla', value: total_sales_salla, transaction_type: 'salla' },
            { title: 'other', value: total_sales_other, transaction_type: 'other' },
         ]
      },
      totalPurchases() {
         const { used_18, used_21, used_22, used_24, used_total } = this.report?.purchases || {};
         return [
            { title: 'used 18', value: used_18, transaction_type: 'used_final_18' },
            { title: 'used 21', value: used_21, transaction_type: 'used_final_21' },
            { title: 'used 22', value: used_22, transaction_type: 'used_final_22' },
            { title: 'used 24', value: used_24, transaction_type: 'used_final_24' },
            { title: 'total', value: used_total, transaction_type: 'used_final_total' },
         ]
      },
      supplierPayments() {
         const { used_18, used_21, used_22, used_24 } = this.report?.supplier_payments || {};
         return [
            { title: 'used 18', value: used_18, unit: 'gram', },
            { title: 'used 21', value: used_21, unit: 'gram', },
            { title: 'used 22', value: used_22, unit: 'gram', },
            { title: 'used 24', value: used_24, unit: 'gram', },
         ]
      },
      totalDeposits() {
         const { deposits_cash, deposits_bank, incoming_trans, deposits_total } = this.report?.deposits || {};
         return [
            { title: 'cash deposits', value: deposits_cash },
            { title: 'bank deposits', value: deposits_bank },
            { title: 'incoming transfer', value: incoming_trans },
            { title: 'total deposits', value: deposits_total },
         ]
      },
      totalExpenses() {
         const { expenses_cash, outgoing_trans, expenses_total } = this.report?.expenses || {};
         return [
            { title: 'cash expenses', value: expenses_cash },
            { title: 'outgoing transfer', value: outgoing_trans },
            { title: 'total expenses', value: expenses_total },
         ]
      },

   },

   methods: {

   },
};
</script>

<style lang="scss" scoped></style>