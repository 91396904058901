import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Error Pages
import Error404 from "../views/ErrorPages/Error404.vue";
import Error500Vue from "../views/ErrorPages/Error500.vue";

// Authentication pages
import AuthVue from "../views/Auth/Auth.vue";
import LoginPageVue from "../views/Auth/LoginPage.vue";
import ForgetPassword from "../views/Auth/ForgetPassword.vue";


// Authorized Pages

import Dashboard from "../views/Dashboard.vue";
import { POSRoutes } from "@/router/POSRoutes";
// Close Shift
import ShiftClosePageVue from "../views/Auth/ShiftClosePage.vue";

import AutoLogin from "../views/Auth/AutoLogin.vue";
import ShiftSummery from "../views/Auth/ShiftSummery.vue";
import ShiftSummery2 from "../views/Auth/ShiftSummery2.vue";
import ShiftSummery3 from "../views/Auth/ShiftSummery3.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      // store.dispatch("checkAppVersion").then();
      store.dispatch("checkAuth").then();
      console.log("beforeEnter and checkAuth", store.state.isAuth);
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then(()=>{
          next();
        });
      } else {
        router.push("/login").then();
      }
    },
    children: POSRoutes,
  },
  {
    path: "/auth",
    component: AuthVue,
    redirect: {
      name: "LoginPage",
    },
    children: [
      {
        path: "/",
        name: "LoginPage",
        component: LoginPageVue,
      },
      {
        path: "/login",
        name: "LoginPagee",
        component: LoginPageVue,
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
      },
      {
        path: "/close-shift",
        name: "ShiftClosePage",
        component: ShiftClosePageVue,
      },
      {
        path: "/auto-login",
        name: "AutoLogin",
        component: AutoLogin,
        meta: {
          title: "auto login",
        },
      },
      {
        path: "/shift-summery",
        name: "ShiftSummery",
        component: ShiftSummery,
        meta: {
          title: "shift",
        },
      },
      {
        path: "/shift-summery-2",
        name: "ShiftSummery2",
        component: ShiftSummery2,
        meta: {
          title: "shift",
        },
      },
      {
        path: "/shift-summery-3/:id",
        name: "ShiftSummery3",
        component: ShiftSummery3,
        meta: {
          title: "shift",
        },
      },
    ],
  },
  {
    path: "/error500",
    name: "Error500",
    component: Error500Vue,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
