<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center text--disabled">
                <strong>{{ $t('sales') | capitalize }} . {{ $t('reports') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-014', 1)">
              <v-btn to="/reports/shift-report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-014").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-018', 1)">
              <v-btn to="/reports/customer-supplier-statement" outlined rounded :min-width="120" style="height: 40px"
                small color="primary" depressed>{{ $global.FilterPermissions("06-018").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-020', 1)">
              <v-btn to="/reports/supplier-statement" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("02-020").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-012', 1)">
              <v-btn to="/reports/full_report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-012").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-010', 1)">
              <v-btn to="/reports/sales_notice_report" active-class="primary" rounded :min-width="120"
                style="height: 40px" small color="transparent" depressed>{{
                  $global.FilterPermissions("06-010").sub_title
                }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('03-004', 1)">
              <v-btn to="/reports/barcode_report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("03-004").sub_title }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :tooltipTableHeader="tooltipTableHeader" :searchMethod="getReports" :tooltipRow="false"
            :maxPDFRows="9" :searchBtn="false" :searchByDateRange="false" :viewSearch="false" pdfOrientation="landscape"
            :isLoading="pageData.isLoading" :footerData="footerData" noDataText="please select to view reports"
            :data="reportsRow" :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 280">
            <template v-slot:td="{ row, header }">
              <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                <span v-else> {{ row[header.key] || 0 | float }}</span>
              </span>
            </template>

            <template v-slot:inputs>
              <v-row align="center" class="mt-n7">

                <GenericInput type="date" :maxDate="to_date || today" :clearable="true" :value="from_date"
                  @input="from_date = $event" label="from" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />

                <GenericInput type="date" :minDate="from_date" :maxDate="today" :clearable="true" :value="to_date"
                  @input="to_date = $event" label="to" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />

                <GenericInput type="select" :clearable="true" :lookups="types" :value="type" @input="type = $event"
                  label="type" :multi="false" :required="false" paddingY="pt-3" selected_label="title"
                  selected_prop="id" :hide-details="true" :isLoading="false" :cols="[12, 'auto', 2]" />

                <GenericInput type="autocomplete" :clearable="true" :lookups="categories" :value="category_id"
                  @input="category_id = $event" label="caliber" :multi="true" :required="false" paddingY="pt-3"
                  selected_label="title" selected_prop="category_id" :hide-details="true" :isLoading="false"
                  :cols="[12, 'auto', 2]" />


                <GenericInput type="select" :clearable="true" :lookups="subcategories" :value="sub_category_id"
                  @input="sub_category_id = $event" label="item" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                  :cols="[12, 'auto', 2]" />

                <GenericInput type="text" :clearable="true" :value="item_code" :outlined="true"
                  @input="item_code = $event" label="item code" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />

                <GenericInput type="select" :clearable="true" :lookups="branches" :value="branch_id"
                  @input="branch_id = $event" label="branch" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                  :cols="[12, 'auto', 2]" />

                <GenericInput type="text" :clearable="true" :value="invoice_code" @input="invoice_code = $event"
                  :outlined="true" label="invoice code" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />


                <GenericInput type="select" :clearable="true"
                  :lookups="[{ id: 1, name: $i18n.t('simplified') }, { id: 2, name: $i18n.t('taxed') }]"
                  :value="customer_type" @input="customer_type = $event" label="invoice type" :multi="false"
                  :required="false" paddingY="pt-3" selected_label="name" selected_prop="id" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />



                <GenericInput type="select" :clearable="true"
                  :lookups="[{ id: 1, name: $t('cash') }, { id: 2, name: $t('network') }, { id: 3, name: $t('transform') }, { id: 7, name: $t('tabby') }, { id: 8, name: $t('tamara') }, { id: 9, name: $t('nearpay') }, { id: 10, name: $t('stc pay') }, { id: 11, name: $t('salla') }]"
                  :value="payment_type" @input="payment_type = $event" label="payment type" :multi="false"
                  :required="false" paddingY="pt-3" selected_label="name" selected_prop="id" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />


                <GenericInput type="select" :clearable="true" :lookups="sales_agents" :value="sales_agent_id"
                  @input="sales_agent_id = $event" label="delegate" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                  :cols="[12, 'auto', 2]" />
                <v-col cols="12" md="2" class="py-1 d-flex justify-end">
                  <ExportBtn :exportData="reportsRow" :openOnHover="false" :header="tableHeader" width="100"
                    :footerData="footerData" :groupHeader="[]" classes="gray3 white--text"
                    :attachmentFileName="pageData.entityName" :printTitle="pageData.entityName" :maxPDFRows="20"
                    v-if="reportsRow.length > 0 && $global.CheckAction(screen_code, 5)">
                    <template v-slot:td="{ row, header }">
                      <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                        <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                        <span v-else> {{ row[header.key] || 0 | float }}</span>
                      </span>
                    </template>
                    <template v-slot:afterTable>
                      <ReportTotals :reportsRow="reportsRow" v-if="reportsRow.length" />
                    </template>
                    <template v-slot:pdf_footer="{ isLastPage }">
                      <ReportTotals :reportsRow="reportsRow" v-if="reportsRow.length && isLastPage" />
                    </template>
                  </ExportBtn>
                  <v-btn :loading="pageData.isLoading" @click="getReports()" :height="38" class="ms-2 shadow"
                    color="success">
                    {{ $t("search") }}
                    <v-icon size="18" right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>

              </v-row>
            </template>
            <template v-slot:afterTable>
              <ReportTotals :reportsRow="reportsRow" v-if="reportsRow.length" />
            </template>
          </DynamicTable>
        </v-col>

      </v-row>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import GenericInput from "@/components/GenericInput";
import ExportBtn from "@/components/ExportBtn.vue";
import { EntityMixins } from "@/mixins/EntityMixins";
import { SalesReportMixins } from "@/mixins/SalesReportMixins";
import ReportTotals from "../ReportTotals";
export default {
  name: "FinalReportEntity",
  mixins: [EntityMixins, SalesReportMixins],
  data: () => ({
    screen_code: '06-010'
  }),
  components: {
    DynamicTable,
    GenericInput,
    ReportTotals,
    ExportBtn
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.endpoint = "sale_report";
    this.getData();
  },
  methods: {

  },
};
</script>
